<!--  -->
<template>
  <div class="searchResultBox">
    <div class="title">Product</div>
    <van-row v-if="productList">
      <van-col span="24">
        <van-col
          class="productItem"
          span="7"
          v-for="(item, index) in productList"
          :key="index"
        >
          <img class="img" :src="item.imgSrc" alt="productTypeImg" />
          <div class="pcName">{{ item.name }}</div>
          <div class="vm"><a :href="item.link">View More</a></div>
        </van-col>
      </van-col>
    </van-row>
    <van-row class="info" v-else>
      No matching resul
    </van-row>
    <div class="title">Accessory</div>
    <van-row v-if="productList">
      <van-col span="24" v-if="accessoryList">
        <van-col
          class="accessoryItem"
          span="7"
          v-for="(item, index) in accessoryList"
          :key="index"
        >
          <img class="img" :src="item.imgSrc" alt="accessoryTypeImg" />
          <div class="acName">{{ item.name }}</div>
          <div class="vm"><a :href="item.link">View More</a></div>
        </van-col>
      </van-col>
    </van-row>
    <van-row class="info" v-else>
      No matching resul
    </van-row>
    <Rfq></Rfq>
  </div>
</template>

<script>
import Rfq from "../components/Rfq";
import jquery from "jquery";

export default {
  data() {
    return {
      video: {
        url: "",
        cover: "",
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: false,
      },
      keyword: "",
      productList: null,
      accessoryList: null,
    };
  },
  created() {
    this.keyword = this.$route.params.keyword;

    const baseURL = this.$store.state.baseURL;
    let that = this;

    //  产品搜索
    jquery.ajax({
      url: baseURL + "productItem/find/ts/like",
      method: "POST",
      data: JSON.stringify({ name: that.keyword }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productList = res._05_response_body_multiple;
      },
    });

    //  配件搜索
    jquery.ajax({
      url: baseURL + "accessoryItem/find/ts/like",
      method: "POST",
      data: JSON.stringify({ name: that.keyword }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.accessoryList = res._05_response_body_multiple;
      },
    });
  },
  components: {
    Rfq,
  },
};
</script>
<style lang="less" scoped>
.searchResultBox {
  padding: 20px 8px;
  .title {
  color: #19939B;
font-size: 28px;
font-weight: bold;
}
.info {
  font-size: 16px;
color: #ccc;
}
}
</style>
