<!--  -->
<template>
  <div>
    <div class="rfq_box">
      <form action="javascript:void 0">
        <input
          :placeholder="rfqData.copywriting"
          @keyup.13="checkMSGData()"
          type="search"
          v-model="msgData.content"
        />
        <i
          @click="checkMSGData()"
          :class="rfqData.iconCode"
          class="iconfont"
        ></i>
      </form>
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      rfqData: {},
      msgData: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
    };
  },
  created() {},
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "inquiryCommon/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.rfqData = res._04_response_body_single[0];
      },
    });
  },
  methods: {
    checkMSGData: function() {
      console.log(this.msgData.content.length);
      if (this.msgData.content.length < 1) {
        alert("Please enter the content");
        return false;
      } else {
        this.postMessage();
      }
    },
    checkMSGDataInit: function() {
      this.msgData.name = "";
      this.msgData.email = "";
      this.msgData.phone = "";
      this.msgData.content = "";
    },
    postMessage: function() {
      const baseURL = this.$store.state.baseURL;
      let that = this;
      jquery.ajax({
        url: baseURL + "message/insert",
        method: "POST",
        data: JSON.stringify(this.msgData),
        contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
        dataType: "JSON", //指定 返回 数据 类型
        success: function(res) {
          that.checkMSGDataInit();
          alert("success");
        },
      });
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.rfq_box {
max-width: 768px;
  background: white;
  width: 100%;
  padding: 8px;
  border-top: 1px solid #19939b;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  // left: 0px;
  // right: 0px;
z-index: 99999;
  form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    i {
      font-size: 32px;
    }
    input {
      flex: 1;
      border: 0px;
      outline: none;
      padding: 4px;
    }
  }
}
</style>
