<!--  -->
<template>
  <div class="projectItemBox">
    <van-row gutter="20" v-for="(item, index) in projectList" :key="index">
      <van-col span="24">
        <div class="left">
          <h1>{{ item.title }}</h1>
          <img v-image-preview :src="item.imgSrc" align="right" alt="projectImg" />
          <p>{{ item.content }}</p>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      projectList: null,
    };
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "projects/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.projectList = res._05_response_body_multiple;
      },
    });
  },
  created() {},
};
</script>
<style lang="less" scoped>
.projectItemBox {
  padding: 16px;
  margin-top: 15px;
  .van-row {
    padding-bottom: 25px;
    border-bottom: 1px solid #e8e8e8;
  margin-bottom: 25px;
  }

  .left,
  .right {
    h1 {
      font-size: 22px;
      color: #19939b;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      // text-indent: 2em;
      word-break: break-all;
    }

    img {
      width: 56%;
      margin-left: 15px;
    }
  }
}
</style>
