<!--  -->
<template>
  <van-row>
    <van-col
      @click="change(item)"
      span="6"
      v-for="(item, index) in accessoryTypeList"
      :key="index"
    >
      <img :src="item.imgSrc" alt="accessoryTypeImg" />
      <div class="title">{{ item.title }}</div>
    </van-col>
  </van-row>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      accessoryTypeList: null,
    };
  },
  mounted() {
    this.initData();
  },
  created() {},
  methods: {
    change: function(currentType) {
      this.initData();
      this.$data.accessoryTypeList = this.$data.accessoryTypeList.filter(
        function(item) {
          return item.typeName === currentType.typeName;
        }
      );
    },

    initData: function() {
      const baseURL = this.$store.state.baseURL;
      let that = this;
      jquery.ajax({
        url: baseURL + "accessoryType/find/ts",
        method: "POST",
        contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
        dataType: "JSON", //指定 返回 数据 类型
        success: function(res) {
          that.accessoryTypeList = res._05_response_body_multiple;
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
img {
  width: 100%;
}
.title {
  text-align: center;
}
</style>
