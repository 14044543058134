<!--  -->
<template>
  <van-row gutter="20" class="basePointBox">
    <van-col span="24"><img :src="imgSrcb" alt=""/></van-col>
    <van-col span="24" class="pointBox">
      <van-col span="10" class="point" v-for="(item, index) in pointData" :key="index">
        {{ item.copywriting }}
    </van-col>
    </van-col>
    
  </van-row>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      uuidb: this.uuid,
      imgSrcb: this.imgSrc,
      pointData: null,
    };
  },
  props: ["uuid", "imgSrc"],
  created() {

    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "servicePageBasePoint/find/paging?page=1&limit=6",
      method: "POST",
      data: JSON.stringify({ uuid: that.uuidb }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.pointData = res._05_response_body_multiple;
      },
    });
    jquery.ajax({
      url: baseURL + "servicePageBasePoint/find/ts/right",
      method: "POST",
      data: JSON.stringify({ uuid: that.uuidb }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.rightPointData = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.basePointBox {
  padding: 8px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  .point {
    background: rgb(109, 109, 109);
    color: white;
    text-align: center;
    padding: 3px;
    margin: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
  }
}
.pointBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
