<!--  -->
<template>
  <div class="concept">
    <center>——  концепций обслуживания  ——</center>
    <div class="borddr">
      <div class="item" v-for="(item, index) in conceptList" :key="index">
        <div class="sup">
          <sup>{{ index + 1 }}</sup
          >{{ item.titleI }}
        </div>
        <div class="sub">{{ item.titleIi }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      conceptList: null,
      uuidb: this.uuid,
    };
  },
  props: ["uuid"],
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "servicePageBaseConcepts/find/ts",
      method: "POST",
      data: JSON.stringify({ uuid: that.uuidb }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.conceptList = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.concept {
  background: #f7f6f6;
  padding: 8px;
  .borddr {
    border: 1px dashed #19939b;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      width: 100%;
      margin: 8px 0px;
      padding: 3px;
      .sup {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        sup {
          padding: 2px 5px;
          border-radius: 50%;
          margin: 0px 4px;
          border: 1px solid #19939b;
        }
      }
      .sub {
        color: #ccc;
        text-align: left;
        font-size: 16px;
      }
    }
  }
}
center {
  font-size: 22px;
  margin: 20px 0px;
  font-weight: bolder;
}
</style>
