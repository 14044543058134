import { render, staticRenderFns } from "./ContactPanel.vue?vue&type=template&id=ba8b9e26&scoped=true&"
import script from "./ContactPanel.vue?vue&type=script&lang=js&"
export * from "./ContactPanel.vue?vue&type=script&lang=js&"
import style0 from "./ContactPanel.vue?vue&type=style&index=0&id=ba8b9e26&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba8b9e26",
  null
  
)

export default component.exports