<template>
  <div class="solutionItem">
  <SolutionItem></SolutionItem>

  <Rfq></Rfq>
  </div>
</template>

<script>
import SolutionItem from '../components/SolutionItem.vue'
import Rfq from '../components/Rfq'

export default {
  name: "Solution",
  components: {
    SolutionItem,
    Rfq
  },
};
</script>
