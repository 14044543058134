<!--  -->
<template>
  <div class="searchBox">
    <form action="javascript:void 0" >
      <i :class="searchData.iconCode" class="iconfont"></i>
      <input
        id="searchInput"
        v-model="keyword"
        :placeholder="searchData.placeholdText"
        @keyup.13="show()"
        type="search"
      />
    </form>
  </div>
</template>

<script>
import jquery from "jquery";

export default {
  data() {
    return {
      searchData: {},
keyword:""
    };
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "searchInput/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.searchData = res._04_response_body_single;
      },
    });
  },

  created() {},
  methods: {
    show: function() {
    this.$router.replace('/searchResult/'+this.keyword)
			//this.$router.push('/home')
},
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  overflow: hidden;
}
.searchBox i {
  line-height: 29px;
  margin-right: 5px;
}

form {
  display: flex;
}

input {
  border: none;
  outline: none;
  padding: 4px;
  border-radius: 4px;
  background: #f3f3f3;
  width: calc(100% - 30px);
  font-size: 12px;
-webkit-appearance:none;
}
</style>
