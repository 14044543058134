<!--  -->
<template>
  <div class="accessoryBox">
    <!-- <AccessoryType></AccessoryType> -->
    <van-row class="accessoryRow">
      <AccessoryItem></AccessoryItem>
    </van-row>
    <Rfq></Rfq>
  </div>
</template>

<script>
import AccessoryType from "../components/AccessoryType";
import AccessoryItem from "../components/AccessoryItem";
import Rfq from "../components/Rfq";
export default {
  data() {
    return {};
  },

  components: {
    AccessoryType,
    AccessoryItem,
    Rfq,
  },
};
</script>
<style lang="less" scoped>
.accessoryBox {
  padding: 8px;
  .accessoryRow {
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
</style>
