<!--  -->
<template>
  <div>
    <!-- 两端对齐 -->
    <van-row class="logo_sites" type="flex" justify="space-between">
      <van-col span="10">
        <router-link class="link" to="/home">
          <img :src="logoData.logoSrc" alt="LOGO" />
        </router-link>
      </van-col>
      <van-col span="8"><Websites></Websites></van-col>
    </van-row>

    <van-row class="search_menu" type="flex" justify="space-between">
      <van-col span="12">
        <Search />
      </van-col>
      <van-col class="menu" span="4"><Menu></Menu></van-col>
    </van-row>
  </div>
</template>

<script>
import Menu from "./Menu";
import Websites from "./Websites";
import Search from "./Search";
import jquery from "jquery";

export default {
  data() {
    return {
      value: "",
      logoData: "",
    };
  },
  components: {
    Menu,
    Search,
    Websites,
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "logo/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.logoData = res._04_response_body_single;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.logo_sites {
  display: flex;
  justify-content: space-between;

  img {
    width: 100%;
    height: 100%;
    max-width: 150px;
  }
}
.logo_sites {
  margin-top: 10px;
  display: flex;
  width: 100%;
  max-width: 768px;
  max-height: 64px;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #e6e6e6;
  position: fixed;
  top: 0px;
  background: white;
  margin: 0 auto;
  z-index: 999999;
  .link {
    display: flex;
  }
}

.search_menu {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.search_menu > div {
  padding: 8px;
}

.search_menu > .search,
.search_menu > .menu {
  width: 50%;
}
</style>
