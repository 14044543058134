<!--  -->
<template>
  <div>
    <ProductIntro :uuid="productUUID"></ProductIntro>
    <Features :uuid="productUUID"></Features>
    <ProductDetails :uuid="productUUID"></ProductDetails>
    <ProductDisplay :uuid="productUUID"></ProductDisplay>
    <Rfq></Rfq>
  </div>
</template>

<script>
import ProductIntro from "../components/ProductIntro";
import Features from "../components/Features";
import ProductDetails from "../components/ProductDetails";
import ProductDisplay from "../components/ProductDisplay";
import Rfq from "../components/Rfq";

export default {
  data() {
    return {
      productUUID: null,
    };
  },
  components: {
    ProductIntro,
    Features,
    ProductDetails,
    ProductDisplay,
    Rfq,
  },
  created() {
    this.productUUID = this.$route.params.uuid;
  },
};
</script>
<style lang="less" scoped></style>
