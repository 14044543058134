<!--  -->
<template>
  <div class="serviecBox" v-if="serviceData">
    <BasePoint :uuid="serviceData.uuid" :imgSrc="serviceData.imgSrc"></BasePoint>
    <Concept :uuid="serviceData.uuid"></Concept>
    <ContactPanel :uuid="serviceData.uuid"></ContactPanel>
    <Rfq></Rfq>
  </div>
</template>

<script>
import BasePoint from "../components/BasePoint.vue";
import Concept from "../components/Concept.vue";
import ContactPanel from "../components/ContactPanel.vue";

import Rfq from "../components/Rfq";
import jquery from "jquery";

export default {
  name: "Solution",
  data() {
    return {
      serviceData: null,
    };
  },
  components: {
    BasePoint,
    Concept,
    ContactPanel,
    Rfq,
  },
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "servicePage/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.serviceData = res._04_response_body_single;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.serviecBox {
  padding: 8px;
}
</style>
