<!--  -->
<template>
  <div class="snsbar">
    <div v-for="(item, index) in sns_list" :key="index" class="item">
      <a :href="item.linked">
        <i class="iconfont c93" :class="item.iconCode"></i>
      </a>
      <span class="c93">{{ item.copywriting }}</span>
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  name: "SnsBar",
  data() {
    return {
      sns_list: [],
    };
  },
  created() {
    
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "footLinked/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.sns_list = res._05_response_body_multiple;
      },
    });
},
};
</script>
<style lang="less" scoped>
* {
  cursor: pointer;
}
.item * {
  display: block;
  text-align: center;
  color: #222;
}
.item:hover * {
  color: #19939b !important;
}

.snsbar {
  padding: 8px 0px;
  position: fixed;
  display: flex;
  bottom: 0px;
  justify-content: space-around;
  width: 100%;
  border-top: 2px solid #19939b;
  background: white;
  z-index: 999;
  max-width: 768px;
  span {
    font-size: 16px;
  }
}
</style>
