<template>
  <div class="activityItem">
    <van-swipe class="dh-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in localItemBannerList" :key="index">
        <img v-image-preview v-lazy="item.imgSrc" />
      </van-swipe-item>
    </van-swipe>

    <div class="infoBox">
      <div class="pubTime">{{ item.createTime | showDate }}</div>
      <div class="title">{{ item.title }}</div>
      <div class="logo">
        <img :src="item.imgSrc" alt="logo" />
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import jquery from "jquery";
import { formatDate } from "../assets/dateFormat";

export default {
  data() {
    return {
      localItem: this.item,
      localItemBannerList: null,
    };
  },
  props: ["item"],

  methods: {},

  filters: {
    showDate(value) {
      const date = new Date(value);
      // 2.将date进行格式化
      return formatDate(date, "yyyy-MM");
    },
  },
  created() {

    const baseURL = this.$store.state.baseURL;
    let that = this;

    jquery.ajax({
      url: baseURL + "activityBanner/find/pid",
      method: "POST",
      data: JSON.stringify({ pId: that.localItem.uuid }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.localItemBannerList = res._05_response_body_multiple;
      },
    });
  },
};
</script>

<style lang="less" scoped>
.activityItem {
  padding: 8px;

  .infoBox {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 35px;
    margin: 4px auto;
    * {
      box-sizing: border-box;
    }
    .pubTime {
      background: #939393;
      color: white;
      padding: 4px;
      text-align: center;
      // flex: 2;
      display: flex;
      align-items: center;
    }
    .title {
      color: black;
      padding: 4px 30px;
      flex: 3;
      line-height: 27px;
    }
    .logo > img {
      flex: 2;
      height: 100%;
      max-width: 80px;
    }
  }
}

.dh-swipe {
  height: 240px;
}

img {
  width: 100%;
}
</style>
