<!--  -->
<template>
  <div class="contactPageBox">
    <ContactPage></ContactPage>
    <Rfq></Rfq>
  </div>
</template>

<script>
import Rfq from "../components/Rfq";
import ContactPage from "../components/ContactPage";
import jquery from "jquery";

export default {
  data() {
    return {
    };
  },
  created() {
  },
  components: {
    Rfq,
    ContactPage,
  },
};
</script>
<style lang="less" scoped>
.contactPageBox {
padding: 8px;
}
</style>
