<template>
  <div class="activityItem">
    <div class="abox" v-if="activityList">
    <ActivityItem v-for="(item,index) in activityList" :key="index" :item="item"></ActivityItem>
</div>
    <Rfq></Rfq>
  </div>
</template>

<script>
// @ is an alias to /src

import ActivityItem from "../components/ActivityItem";
import Rfq from "../components/Rfq";
import jquery from "jquery";

export default {
  data(){
  return {
activityList:null}
},
  name: "ProductItem",
  components: {
    ActivityItem,
    Rfq,
  },
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;

    jquery.ajax({
      url: baseURL + "activities/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.activityList = res._05_response_body_multiple;
      },
    });
  },
};
</script>
