<template>
  <div class="home">
    <Banner></Banner>
    <Factory></Factory>
    <SnsBar></SnsBar>
    <Customer></Customer>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import Factory from "../components/Factory.vue";
import Customer from "../components/Customer.vue";
import SnsBar from "../components/SnsBar.vue";

export default {
  data() {
    return {};
  },

  components: {
    SnsBar,
    Banner,
    Factory,
    Customer,
  },
};
</script>
<style lang="less" scoped></style>
