<template>
  <div v-cloak v-clickoutside="outsideClose" class="dropmain">
    <i :class="iconCodeI" class="iconfont"></i>
    <div @click="show = !show" class="dropbutton">
      Язык
      <div class="dropdown" v-show="show">
        <a v-for="(item, index) in siteList" :key="index" :href="item.siteLink"
          ><img :src="item.imgSrc" alt=""
        /></a>
      </div>
    </div>
    <i :class="iconCodeII" class="iconfont"></i>
  </div>
</template>

<script>
import jquery from "jquery";

export default {
  name: "DropDown",
  data() {
    return {
      show: false,
      iconCodeI: "icondiqiu",
      iconCodeII: "iconpaixujiantouxia",
      show: false,
      siteList: [],
    };
  },

  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "siteSelection/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.siteList = res._05_response_body_multiple;
      },
    });
  },

  directives: {
    clickoutside: {
      bind(el, binding, vnode) {
        function documentHandler(e) {
          if (el.contains(e.target)) {
            return false;
          }
          if (binding.expression) {
            binding.value(e);
          }
        }

        function KeyUp(e) {
          if (e.keyCode == 27) {
            if (binding.expression) {
              binding.value(e);
            }
          }
        }
        el.__vueClickOutSize__ = documentHandler;
        el.__vueKeyup__ = KeyUp;

        document.addEventListener("keyup", KeyUp);
        document.addEventListener("click", documentHandler);
      },
      unbind(el, binding) {
        document.removeEventListener("click", el.__vueClickOutSize__);
        delete el.__vueClickOutSize__;

        document.removeEventListener("keyup", el.__vueKeyup__);
        delete el.__vueKeyup__;
      },
    },
  },
  methods: {
    outsideClose() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
[v-cloak] {
  display: none;
}

.dropmain {
  // width: 90px;
  display: flex;
  align-items: center;
  height: 100%;
justify-content: flex-end;
}
.dropmain i {
  line-height: 28px;
}

.dropbutton {
  display: block;
  width: 80px;
  padding: 5px 4px;
  color: #222;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.dropdown {
  width: 80px;
  padding: 5px;
  text-align: center;
  font-size: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  text-align: left;
  margin-top: 2px;
  position: absolute;
  z-index: 999;
  > a {
    display: block;
    height: 29px;
    margin: 2px 0px;
    > img {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
</style>
