<!--  -->
<template>
  <van-row class="contactPanel" v-if="contactsData">
    <van-col span="24" class="img"
      ><img v-image-preview :src="contactsData.imgI" alt=""
    /></van-col>
    <van-col span="24" class="panel">
      <img v-image-preview class="bg" :src="contactsData.imgIi" alt="" />
      <p class="title">{{ contactsData.title }}</p>
      <div class="left">
        <div class="wayItem" v-for="(item, index) in contactList" :key="index">
          <i :class="item.iconCode" class="iconfont"></i>
          {{ item.copywriting }}
        </div>
      </div>
      <div class="right">
        {{ contactsData.intro }}
      </div>
    </van-col>
  </van-row>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      uuidb: this.uuid,
      contactsData: null,
      contactList: null,
    };
  },
  props: ["uuid"],
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;

    jquery.ajax({
      url: baseURL + "servicePageBaseContacts/find/ts/pid",
      method: "POST",
      data: JSON.stringify({ pId: that.uuidb }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.contactsData = res._04_response_body_single;
        console.log(that.contactsData);

        //  获取当前联系小组的所有联系方式
        console.log(that.contactsData.uuid);
        jquery.ajax({
          url: baseURL + "servicePageBaseContact/find/ts/pid",
          method: "POST",
          data: JSON.stringify({ pId: that.contactsData.uuid }),
          contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
          dataType: "JSON", //指定 返回 数据 类型
          success: function(res) {
            console.log(that.contactsData);
            that.contactList = res._05_response_body_multiple;
          },
        });
        //  获取当前联系小组的所有联系方式
      },
    });
  },
};
</script>
<style lang="less" scoped>
.contactPanel {
  .img {
    img {
      width: 100%;
    }
  }
  .panel {
    // height: 200px;
    position: relative;

    box-sizing: border-box;
    .left,
    .right {
      width: 100%;
      float: left;
      padding: 8px;
    }
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .wayItem {
        position: relative;
        width: 60%;
        padding: 3px;
        background: #19939b;
        color: white;
        margin: 8px 0px;
        border-radius: 8px;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 5px 8px;
        line-height: 28px;
        i {
          font-size: 28px;
          color: white;
        }
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.6;
      z-index: -1;
    }
    .title {
      padding: 8px;
      font-size: 20px;
      color: #19939b;
      font-weight: bold;
      margin: 10px 0px;
    }
    .right {
      color: #19939b;
      text-indent: 2em;
      font-size: 18px;
      word-break: break-all;
    }
  }
}
</style>
