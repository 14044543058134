<!--  -->
<template>
  <div>
    <van-row gutter="20">
      <van-col
        span="12"
        class="factory_item"
        v-for="(item, index) in factory_list"
        :key="index"
      >
        <img v-image-preview :src="item.imgSrc" alt="factoryPic" />
        <div class="textBox">
          <div class="left">
            <span class="sup">{{ item.title }}</span>
            <span class="sub c93">{{ item.copywriting }}</span>
          </div>
          <div class="right">
            <a :href="item.link">{{ item.linkCopywriting }}</a>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      factory_list: [],
    };
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "factory/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.factory_list = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.factory_item {
  margin: 10px 0px;
  img {
    width: 100%;
  }

  > .textBox {
    // display: flex;
    .left > .sup,
    .left > .sub {
      display: block;
    }

    .left > .sup {
      font-size: 18px;
    }

    .left > .sub {
      font-size: 16px;
      color: #ccc;
    }

    .right {
      // display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      a {
        font-size: 16px;
        color: #939393 !important;
      }
    }
  }
}
</style>
