<template>
  <div class="projectsBox">
    <ProjectItem></ProjectItem>
    <Rfq></Rfq>
  </div>
</template>

<script>
// @ is an alias to /src

import ProjectItem from '../components/ProjectItem'
import Rfq from '../components/Rfq'

export default {
  name: "Product",
  components: {
    ProjectItem,
    Rfq
  },
};
</script>
<style lang="less" scoped>
</style>
