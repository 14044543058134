import Vue from "vue";
import VueRouter from "vue-router";
import Products from "../views/Products.vue";
import Accessories from "../views/Accessories.vue";
import Product from "../views/Product.vue";
import Projects from "../views/Projects.vue";
import Activites from "../views/Activites.vue";
import Solution from "../views/Solution.vue";
import AboutUs from "../views/AboutUs.vue";
import Service from "../views/Service.vue";
import Contact from "../views/Contact.vue";
import SearchResult from "../views/SearchResult.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home },
  { path: "/products", component: Products },
  { path: "/accessories", component: Accessories },
  { path: "/product/:uuid", component: Product },
  { path: "/projects", component: Projects },
  { path: "/activites", component: Activites },
  { path: "/solution", component: Solution },
  { path: "/aboutus", component: AboutUs },
  { path: "/service", component: Service },
  { path: "/contact", component: Contact },
  { path: "/searchResult/:keyword", component: SearchResult },
  { path: "/home", component: Home },
];

const router = new VueRouter({
  routes, // (缩写) 相当于 routes: routes
});

export default router;
