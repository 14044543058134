<template>
  <div class="certificateBar">
    <van-row>
      <van-col span="24" class="title">Свидетельство > </van-col>
      <van-col span="24">
        <div class="swiper-container certificate">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in certificateList"
                :key="index">
              <img v-image-preview
                :src="item.imgSrc"
                :alt="item.remark"
              />
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Swiper from "swiper";
import jquery from "jquery";

export default {
  data() {
    return {
      certificateList: {},
    };
  },
  methods: {},
  mounted() {
    var certificate = new Swiper(".certificate", {
      spaceBetween: 5,
      slidesPerView: 3,
      // watchSlidesVisibility: true,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });

    console.log(certificate);

    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "certificate/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.certificateList = res._05_response_body_multiple;
      },
    });
  },
};
</script>

<style lang="less" scoped>
.certificateBar {
  .title {
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
    background: #e8e8e8;
  }
  .certificate {
    padding: 8px;
  }
}
img {
  width: 100%;
}
</style>
