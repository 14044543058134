<!--  -->
<template>
  <div>
    <p class="hind black fs18">Сайт клиента ></p>
    <van-swipe class="dh-swipe" :autoplay="5000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in customer_list" :key="index">
        <img v-lazy="item.imgSrc" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import jquery from "jquery";

Vue.use(Lazyload);

export default {
  data() {
    return {
      customer_list: [],
    };
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "customerSite/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.customer_list = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.dh-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: auto;
}
.dh-swipe .van-swipe-item img {
  width: 100%;
  height: 100% !important;
}
p {
  padding: 8px;
font-weight: bold;
}
</style>
