<!--  -->
<template>
  <div>
    <vueMiniPlayer class="video" :video="video" />

    <div class="copywriting" v-html="aboutus.copywriting"></div>
    <Rfq></Rfq>
  </div>
</template>

<script>
import Rfq from "../components/Rfq";
import jquery from "jquery";

export default {
  data() {
    return {
      aboutus: {},
      video: {
        url: "",
        cover: "",
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: false,
      },
    };
  },
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "aboutUs/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.aboutus = res._04_response_body_single;
        that.video.url = that.aboutus.resSrc;
        console.log(that);
      },
    });
  },
  components: {
    Rfq,
  },
};
</script>
<style lang="less" scoped>
.copywriting {
  padding: 20px 8px;
  text-indent: 2em;
}
.video {
  margin: 15px 0px;
}
</style>
