<template>
  <div class="product">
    <ProductsSwiper></ProductsSwiper>
    <CertificateBarItem></CertificateBarItem>
<Rfq></Rfq>
  </div>
</template>

<script>
// @ is an alias to /src

import ProductsSwiper from "../components/ProductsSwiper";
import CertificateBarItem from "../components/CertificateBarItem.vue";
import Rfq from "../components/Rfq";

export default {
  name: "ProductItem",
  components: {
    CertificateBarItem,
    ProductsSwiper,
    Rfq,
  },
};
</script>
