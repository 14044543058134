<!--  -->
<template>
  <van-row class="productIntroBox" v-if="productData">
    <van-col span="24" class="name">{{ productData.name }}</van-col>
    <!-- <van-col span="24" class="useage">{{ productData.useage }}</van-col> -->
    <van-col span="24" class="intro" v-html="productData.intro"></van-col>
    <van-col span="17" class="img"
      ><img v-image-preview :src="productData.imgSrc" alt="productImg"
    /></van-col>
  </van-row>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      uuidb: this.uuid,
      productData: null,
    };
  },
props: ["uuid"],
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "productItem/find/single",
      method: "POST",
      data: JSON.stringify({ uuid: that.uuidb }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productData = res._04_response_body_single;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.productIntroBox {
  padding: 8px;

  .name{
    font-size: 18px;
font-weight: bold;
padding: 4px 0px;
}
  .useage{
    font-size: 16px;
font-weight: 700;
}
.intro {
  padding: 10px 0px;
  word-break: break-all;
}
  .img {
    img {
      width: 100%;
    }
  }
}
</style>
