<!--  -->
<template>
  <div class="factory_box">
    <p class="hind black fs18 title">фабрика ></p>
    <FactoryItem></FactoryItem>
  </div>
</template>

<script>
import FactoryItem from "../components/FactoryItem.vue";

export default {
  data() {
    return {};
  },

  components: {
    FactoryItem
}
};
</script>
<style lang="less" scoped>
.factory_box {
    padding: 8px;
    padding-top: 21px;
  .title {
    padding-bottom: 10px;
  }
}
p {
margin-bottom: -12px;
font-weight: bold;
}
</style>
