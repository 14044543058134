<!--  -->
<template>
  <div>
    <van-row
      class="productDisplayBox"
      v-for="(item, index) in productDisplayList"
      :key="index"
    >
      <van-col span="24" class="title">{{item.title}}</van-col>
      <van-col span="24" class="img"
        ><img v-image-preview
          :src="item.imgSrc"
          alt=""
      /></van-col>
    </van-row>
  </div>
</template>

<script>
import jquery from "jquery";

export default {
  data() {
    return {
      pid: this.uuid,
      productDisplayList: {},
    };
  },
props: ["uuid"],
  created() {

    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "productDisplayPhoto/find/pid",
      method: "POST",
      data: JSON.stringify({ pId: that.pid }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productDisplayList = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.productDisplayBox {
  padding: 8px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  img {
    width: 100%;
  }
}
</style>
