<!--  -->
<template>
  <div>
    <van-row>
      <van-col
        class="typeItem"
        @click="change(item)"
        span="6"
        v-for="(item, index) in accessoryTypeList"
        :key="index"
      >
        <img class="img" :src="item.imgSrc" alt="accessoryTypeImg" />
        <div class="title">{{ item.title }}</div>
      </van-col>
    </van-row>

    <van-col
      class="accessoryItem"
      span="11"
      v-for="(item, index) in accessoryList"
      :key="index"
    >
      <img v-image-preview class="img" :src="item.imgSrc" alt="accessoryTypeImg" />
      <div class="acName">{{ item.name }}</div>
      <div class="vm"><a :href="item.link">Узнать больше</a></div>
    </van-col>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      accessoryList: null,
      accessoryTypeList: null,
    };
  },
  mounted() {
    this.initData();
    this.initItemData();
  },
  created() {},
  methods: {
    change: function(currentType) {
      this.initItemData(currentType.typeName);
    },

    initData: function() {
      const baseURL = this.$store.state.baseURL;
      let that = this;
      jquery.ajax({
        url: baseURL + "accessoryType/find/ts",
        method: "POST",
        contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
        dataType: "JSON", //指定 返回 数据 类型
        success: function(res) {
          that.accessoryTypeList = res._05_response_body_multiple;
        },
      });
    },

    initItemData: function(typeName) {
      const baseURL = this.$store.state.baseURL;
      let that = this;
      jquery.ajax({
        url: baseURL + "accessoryItem/find/ts",
        method: "POST",
        contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
        dataType: "JSON", //指定 返回 数据 类型
        success: function(res) {
          if(typeName!=null){
            let newList = res._05_response_body_multiple.filter(
              function(item) {
                console.log(item.type);
                console.log(typeName);
                console.log(item.type == typeName);
                return item.type == typeName;
              }
            );

            that.accessoryList = newList;
            console.log(that.accessoryList);
            return;
          }
          that.accessoryList = res._05_response_body_multiple;
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
img {
  width: 100%;
}
.accessoryItem {
  padding: 3px;
  // border: 1px solid #e8e8e8;
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  align-items: center;
  img.img {
    width: 100%;
  }
  .acName {
    margin: 8px auto;
    margin-bottom: 30px;
    text-align: center;
    word-break: break-all;
  }
  .vm {
    width: 100%;
    a {
      // width: 70px;
      margin: 0 auto;
      font-size: 12px;
      padding: 4px;
      border-radius: 3px;
      background: #19939b;
      color: white !important;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
}
.typeItem {
  margin-right: 8px;
  // border: 1px solid #e8e8e8;
  padding: 3px;
  box-sizing: border-box;
  .title {
    text-align: center;
    word-break: break-all;
  }
}
</style>
